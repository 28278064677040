/*
 * Loader 
 */

.mi-loader-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.mi-loader {
    display: block;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 67px;
    height: 67px;
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 54px;
        height: 54px;
        margin: 6px;
        border: 5px solid;
        border-color: #999 transparent transparent transparent;
        border-radius: 50%;
        animation: mi-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
    .mi-loader-child-1 {
        animation-delay: -0.45s;
    }
    .mi-loader-child-2 {
        animation-delay: -0.3s;
    }
    .mi-loader-child-3 {
        animation-delay: -0.15s;
    }
}

@keyframes mi-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}