/*
 * Resume 
 */

.mi-resume {
    &-wrapper {
        border-left: 3px solid $color-border;
    }
    & {
        display: flex;
    }
    &-summary {
        align-self: flex-start;
        flex: 0 0 220px;
        max-width: 220px;
        padding-left: 20px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: -9px;
            top: 6px;
            height: 15px;
            width: 15px;
            border-radius: 100px;
            background: $color-bg-body;
            border: 3px solid $color-border;
        }
    }
    &-year {
        color: $color-body;
        margin-bottom: 0;
    }
    &-company {
        color: #ffffff;
    }
    &-details {
        position: relative;
        padding-left: 50px;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 15px;
            height: 1px;
            width: 30px;
            background: $color-border;
        }
        h5 {
            color: $color-theme;
            margin-bottom: 0;
            font-weight: 700;
        }
        h6 {
            margin-bottom: 0.3rem;
        }
        p {
            margin-bottom: 0;
        }
    }
    @media #{$layout-notebook} {
        &-summary {
            flex: 0 0 170px;
            max-width: 170px;
        }
    }
    @media #{$layout-tablet} {
        &-summary {
            flex: 0 0 170px;
            max-width: 170px;
        }
    }
    @media #{$layout-mobile-lg} {
        flex-wrap: wrap;
        &-details {
            padding-left: 20px;
            &::before {
                left: 3px;
                top: -15px;
                width: 12px;
            }
        }
    }
}