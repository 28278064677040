/*
 * Skills
 */

.mi-skills-area {
    ul {
        padding-left: 1.2rem;
    }
}

.mi-skills {
    display: flex;
    flex-wrap: wrap;
}

.mi-skill {
    padding: 4px 8px 3px 8px;
    margin: 12px 12px 0 0;
    border: 1px solid $color-theme;
    border-radius: 5px;
    &-title {
        font-weight: 700;
        margin: 0;
    }
}