/*
 * Portfolio
 */

.mi-portfolio {
    &-image {
        display: block;
        position: relative;
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(#000, 0.7);
            transition: all 0.3s ease-out 0.1s;
            transform: scaleX(0);
            transform-origin: 0;
        }
        ul {
            margin-bottom: 0;
            padding-left: 0;
            position: absolute;
            z-index: 2;
            left: 0;
            top: 50%;
            text-align: center;
            width: 100%;
            transform: translateY(-50%);
            visibility: hidden;
            opacity: 0;
            margin-top: 30px;
            transition: all 0.2s ease-in-out;
            li {
                list-style: none;
                display: inline-block;
                margin: 0 7px;
                a,
                button {
                    display: inline-block;
                    height: 45px;
                    width: 45px;
                    padding: 5px 0;
                    vertical-align: middle;
                    text-align: center;
                    line-height: 1;
                    background: #777777;
                    color: #ffffff;
                    border: 1px solid #777777;
                    border-radius: 100px;
                    outline: none;
                    font-size: 1.25rem;
                    transition: $transition;
                    svg {
                        height: 100%;
                    }
                    &:hover {
                        background: $color-theme;
                        border-color: $color-theme;
                    }
                }
            }
        }
        img {
            transform: scale(1);
            transition: transform 0.45s;
        }
        &:hover {
            &::before {
                transform: scaleX(1);
                transition: all 0.3s ease-out;
            }
            ul {
                visibility: visible;
                opacity: 1;
                margin-top: 0;
                transition: all 0.2s ease-in-out 0.2s;
            }
            img {
                transform: scale(1.05);
            }
        }
    }
    h5 {
        color: $color-heading;
        margin-top: 15px;
        margin-bottom: 4px;
        font-weight: 700;
        a {
            color: #ffffff;
            transition: $transition;
            &:hover {
                color: $color-theme;
            }
        }
    }
    h6 {
        color: $color-body;
        margin-bottom: 0;
    }
}