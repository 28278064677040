/**
 * Service
 */

.mi-service {
    border: 1px solid $color-border;
    border-top: 5px solid $color-border;
    padding: 30px;
    background: lighten($color-bg-body, 5);
    transition: $transition;
    &-icon {
        margin-bottom: 20px;
        display: inline-block;
        color: $color-theme;
        font-size: 2.5rem;
    }
    h5 {
        font-weight: 600;
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 15px;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: auto;
            bottom: 0;
            height: 2px;
            width: 50px;
            background: $color-border;
        }
    }
    p {
        margin-bottom: 0;
    }
    @media #{$layout-laptop} {
        padding: 20px;
    }
    @media #{$layout-mobile-sm} {
        padding: 20px;
    }
}